<template lang="pug">
    .layout-general
        form.form-general.card-bg-theme.p-6.rounded.shadow(@submit="submit($event)")
            .form-general__header
                h2 {{ page_title }} Indikator
                router-link(to="/indikator")
                    b-button(text="Kembali" text_icon_left="chevron-left" type="button")
            .form-general__body
                .grid.grid-cols-11.gap-8.mb-16
                    .col-span-4.pr-12
                        h2.text-xl.font-bold.mb-2 Tentukan Ancaman
                        p.text-gray-400 Tentukan ancaman terlebih dahulu untuk mengelola indikator
                    .col-span-7
                        .mb-12
                            .field
                                label.field-label.pb-4 Pilih Gatra
                                
                                .relative.p-10.text-center(v-if="status_list_gatra.status === 'loading'")
                                    b-loading(text="Memuat data")

                                template(v-else)
                                    //- .grid.grid-cols-3.gap-4
                                    .grid.grid-cols-3.gap-4(v-if="list_gatra && list_gatra.length > 0")
                                        //- .ui-radio(v-for="item in list_gatra")
                                        .ui-radio(v-for="item in list_gatra" :key="item.id")
                                            input(type="radio" :value="item.id" v-model="model.gatra_id" :disabled="route_name === 'GeneralIndikatorEdit'")
                                            .ui-radio-style
                                                .box-radio.box-radio--default
                                                    span
                                                label {{ item.label }}
                                        //- b-checkbox.mb-4(v-for="item in list_gatra" :value="item.id" :key="item.id" :label="item.label" v-model="choosenGatra")

                                    span(v-else) -
                                
                                span.field-message.text-red-500(v-if="$v.model.gatra_id.$error") Gatra wajib diisi.
                                span.field-message.text-red-500(v-if="error_gatra_id !== ''") {{ error_gatra_id }}

                        .field
                            label.field-label Pilih Ancaman {{ '- '+ gatra_label }}
                            .field.field--icon-right.w-full

                                .field-style.field-style--loading(v-if="status_list_ancaman.status === 'loading'")
                                    b-loading(kind="circular")

                                .relative(v-else)
                                    select.field-style(v-model="model.ancaman_id" :disabled="route_name === 'GeneralIndikatorEdit'")
                                        option(value="") - Pilih Ancaman -
                                        template(v-if="list_ancaman.length > 0")
                                            option(v-for="item in list_ancaman" :value="item.id") {{ item.label }}
                                    span.icon-field
                                        b-icon(name="chevron-down")

                                //- select.field-style(:disabled="model.gatra_id === ''")
                                    option(value="none") - Pilih Ancaman -
                                    option Ancaman Trafik Siber Nasional
                                    option Ancaman 2
                                    option Ancaman 3
                                    
                                //- span.icon-field
                                    b-icon(name="chevron-down")

                            span.field-message.text-red-500(v-if="$v.model.ancaman_id.$error") Ancaman wajib diisi.
                            span.field-message.text-red-500(v-if="error_ancaman_id !== ''") {{ error_ancaman_id }}

                .grid.grid-cols-11.gap-8.mb-16
                    .col-span-4.pr-12
                        h2.text-xl.font-bold.mb-2 Tentukan Indikator
                        p.text-gray-400 Silakan kelola indikator untuk ancaman yang dipilih
                    .col-span-7
                        //- .mb-4.font-bold.font-xl Ancaman Trafik Siber Nasional
                        //- .field
                            strong.mb-4.block Tambah Indikator
                            .flex.items-center.gap-4
                                .flex-grow
                                    .field.w-full
                                        input.field-style(type="text" placeholder="Tulis Indikator disini...")

                                .field
                                    b-button(icon_only="plus" kind="tertiary" type="button" v-tooltip="'Tambahkan indikator'")
                                    
                        .field
                            //- strong.mb-4.block Label
                            label.field-label Label
                            .field.w-full
                                input.field-style(type="text" placeholder="Tulis label ndikator disini..." v-model="model.label")
                                
                                span.field-message.text-red-500(v-if="$v.model.label.$error") Label wajib diisi.
                                span.field-message.text-red-500(v-if="error_label !== ''") {{ error_label }}


                        //- .field
                            strong.mb-4.block Indikator terimpan
                            .flex.items-center.gap-4
                                .flex-grow
                                    .field.w-full
                                        input.field-style(type="text" value="Jumlah trafik siber ilegal nasional dari luar negeri" placeholder="Tulis Indikator disini...")

                                .field
                                    b-button(icon_only="trash" kind="danger" type="button" v-tooltip="'Hapus indikator'")
                            .flex.items-center.gap-4
                                .flex-grow
                                    .field.w-full
                                        input.field-style(type="text" value="Jumlah kasus serangan siber kepada instansi pemerintah" placeholder="Tulis Indikator disini...")

                                .field
                                    b-button(icon_only="trash" kind="danger" type="button" v-tooltip="'Hapus indikator'")
                        //- .field
                            label.field-label Tulis Keywords Indikator
                            vue-tags-input(
                                v-model="tag"
                                :tags="tags"
                                @tags-changed="newTags => tags = newTags"
                            )
                        .field.field--indicator
                            label.field-label Tulis Keyword Indikator
                            vue-tags-input(
                                v-model="tag"
                                :tags="tags"
                                @tags-changed="newTags => tags = newTags"
                                placeholder="Tambah keyword"
                            )

                            span.field-message.text-red-500(v-if="$v.model.keyword.$error") Keyword wajib diisi.
                            span.field-message.text-red-500(v-if="error_keyword !== ''") {{ error_keyword }}

            .form-general__footer
                b-button(text="Reset" type="button" @click.native="reset()")
                b-button(text="Simpan" kind="primary" type="submit")

</template>

<script>
import {mapState} from 'vuex';
import {required, maxLength} from 'vuelidate/lib/validators';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: 'GeneralIndikatorForm',
    components: {
        VueTagsInput
    },
    data() {
        return {
            route_name: '',

            model: {
                gatra_id: '',
                ancaman_id: '',
                label: '',
                keyword: '',
            },

            error_gatra_id: '',
            error_ancaman_id: '',
            error_label: '',
            error_keyword: '',

            tag: '',
            tags: [],
            //- list_gatra: [
            //-     { label: 'Politik'},
            //-     { label: 'Ekonomi'},
            //-     { label: 'Ideologi'},
            //-     { label: 'Sosbud'},
            //-     { label: 'Hankam'},
            //-     { label: 'Misc'},
            //- ]
        };
    },
    validations: {
        model: {
            gatra_id: {
                required,
            },
            ancaman_id: {
                required,
            },
            label: {
                required,
                //- maxLength: maxLength(255)
            },
            keyword: {
                required,
                //- maxLength: maxLength(255)
            },
        },
    },
    computed: {
        ...mapState('graph', [
            'status_list_gatra',
            'list_gatra',
        ]),
        ...mapState('ancaman', {
            status_list_ancaman: state => state.status_list,
            list_ancaman: state => state.list,
        }),
        ...mapState('indikator', [
            'status_detail',
            'detail',
        ]),
        page_title() {
            let title = 'Ubah Indikator';
            if (this.route_name === 'GeneralIndikatorCreate') {
                title = 'Tambah Indikator';
            }
            return title;
        },
        gatra_label() {
            const gatra_id = this.model.gatra_id;
            let gatra = this.list_gatra.filter(function(item) { return item.id === gatra_id });
            return gatra.length > 0 ? gatra[0].label : '';
        },
    },
    watch: {
        page_title() {},
        detail(newVal) {
            this.model.gatra_id = (this.$route.params.gatra_id ? this.$route.params.gatra_id : '');
            //- this.model.ancaman_id = (this.$route.params.ancaman_id ? this.$route.params.ancaman_id : '');
            this.tags = (newVal.keyword ? newVal.keyword.map(function(item) { return {"text":item,"tiClasses":["ti-valid"]}; }) : []);
            this.model.label = (newVal.label ? newVal.label : '');
        },
        //- 'model.keyword': function(newVal) {
        //-     console.log('model.keyword')
        //-     console.log(newVal)
        //- },
        'tags': function(newVal) {
            //- console.log(newVal)
            //- console.log(JSON.stringify(newVal))
            this.model.keyword = newVal.map(function(item) { 
                return item.text;
            });
        },
        'model.gatra_id': function(newVal) {
            this.model.ancaman_id = '';

            if (newVal !== '') {
                this.getAncaman();
            
            } else {
                this.$store.commit('ancaman/setList', {});
            }
        },
        gatra_label() {},
    },
    methods: {
        setEmptyModel(all=true) {
            for (const key in Object.keys(this.model)) {
                if (all === true) {
                    this.model[Object.keys(this.model)[key]] = '';
                }
            }
            this.tag = '';
            this.tags = [];
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_gatra_id = '';
                this.error_ancaman_id = '';
                this.error_label = '';
                this.error_keyword = '';
            } else if (empty !== true && errors !== null) {
                this.error_gatra_id = errors.gatra_id ? errors.gatra_id : '';
                this.error_ancaman_id = errors.ancaman_id ? errors.ancaman_id : '';
                this.error_label = errors.label ? errors.label : '';
                this.error_keyword = errors.keyword ? errors.keyword : '';
            }
        },
        async reset(direct=false) {
            if (direct === true) {
                // this.setEmptyModel(false);
                this.setEmptyModel(true);
                // reset vuelidate
                this.$nextTick(() => {
                    this.$v.$reset();
                });
                this.setErrors(true);
                // window.scrollTo(0,0);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda benar-benar ingin mengosongkan semua data yang sudah diisi?',
                    // text: id,
                    confirmButtonText: 'Ya',
                    confirmButtonColor: '#DD6B55',
                    cancelButtonText: 'Tidak',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                        // if (this.route_name === 'GeneralProfilePage') {
                        //     this.setEmptyModel(false);
                        //     // reset vuelidate
                        //     this.$nextTick(() => { this.$v.$reset(); });
                        //     this.setErrors(true);
                        //     window.scrollTo(0,0);
                        // } else {
                            this.getData();
                        // }
                        }
                    });
            }
        },
        async getData(update=false) {
            await this.reset(true);
            this.$store.dispatch('graph/getListGatra');


            if (this.route_name === 'GeneralIndikatorEdit') {
                Event.$emit('showLoading', true);

                //- console.log(this.$route.params.data);
                this.$store.commit('indikator/setDetail', this.decodeDetailData(this.$route.params.data));

                // temp
                Event.$emit('showLoading', false);

                //- this.$store.dispatch('ancaman/getDetail', this.id)
                //- .then((resp) => {
                //-     if (resp === 'success') {
                //-         Event.$emit('showLoading', false);
                //-     } else {
                //-         Event.$emit('error');
                //-     }
                //- });

            } else {
                // if (this.route_name === 'GeneralIndikatorCreate')
                this.$store.commit('indikator/setDetail', {});
            }
        },
        async getAncaman() {
            await this.$store.dispatch('ancaman/getList', this.model.gatra_id);
            if (this.route_name === 'GeneralIndikatorEdit')
                this.model.ancaman_id = (this.$route.params.ancaman_id ? this.$route.params.ancaman_id : '');
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (const key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes('$')) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0, 0);
                return;
            }

            Event.$emit('showLoading', true);
            let model_data = this.model;
            //- model_data.keyword = JSON.stringify(model_data.keyword);
            // console.log(model_data);
            model_data = Object.keys(model_data).map((key) => model_data[key]);

            if (this.route_name === 'GeneralIndikatorCreate') {
                this.$store.dispatch('indikator/create', model_data)
                    .then((resp) => {
                        if (resp.result === 'success') {
                            if (save_continue === false) {
                                this.$router.push('/indikator');
                            } else {
                                this.$router.push('/indikator/'+ resp.data.data.id +'/edit');
                            }

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Indikator berhasil dibuat',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message && resp.data.message.errors) {
                            // this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                                Event.$emit('showLoading', false);
                                window.scrollTo(0, 0);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });

            // GeneralIndikatorEdit
            } else {
                model_data = [this.detail.id].concat(model_data);

                this.$store.dispatch('indikator/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/indikator');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Indikator berhasil diperbarui',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0, 0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        //
    },
    
    mounted() {
        // 
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.route_name = to.name;
            if (to.name === 'GeneralIndikatorCreate' || to.name === 'GeneralIndikatorEdit') {
                vm.getData();
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        this.route_name = '';
        this.$store.commit('indikator/setDetail', {});
        next();
    },
};
</script>

<style lang="sass">
.field--indicator
    .vue-tags-input
        max-width: 100%
        background-color: var(--bg-field-theme)
        @apply rounded
        .ti-input
            @apply rounded
            padding: 0.5rem 1rem
            border: 1px solid var(--color-primary-500)
            input
                background-color: transparent
                font-size: 1rem
</style>
